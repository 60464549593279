import ScriptModel from '../_utils/script-model'
import { getOverseaReportConfig } from '../../biz-meta'
import { isGp, isMasterEnv } from '../../constants'
import { registerOverseaReportPromisePublicVar } from '../../utils/register-public-var'
import { withResolvers } from '../../utils/with-resolvers'
import { inDisableList } from '../../utils/in-disable-list'

/**
 * 是否启用海外埋点上报
 */
const isEnabled = isGp && isMasterEnv && !inDisableList('oversea-report')

/**
 * 海外埋点上报 Resolver
 */
const resolver = withResolvers()

/**
 * 如果未启用海外埋点上报，则直接 reject
 */
if (!isEnabled) {
  resolver.reject()
}

/**
 * 注册海外埋点上报 Promise
 */
registerOverseaReportPromisePublicVar(resolver.promise)

/**
 * 海外报告统计
 * @description 用于海外埋点上报
 */
export const overseaReport = new ScriptModel({
  condition: isEnabled,
  /**
   * 海外埋点上报脚本, 源码参考: https://gitlab.xsjcs.cn/xfe/oversea-tracker
   */
  script: 'https://static-web.amazingseasuncdn.com/xassets/lib/oversea-report/0.0.x/index.min.js',
  resolve: () => {
    const overseaReportConfig = getOverseaReportConfig()
    if (overseaReportConfig) {
      const { appId, appVersion, ...restConfig } = overseaReportConfig
      restConfig.createCommonAttributes = () => {
        return restConfig
      }
      const overseaReport = (window as any).OverseaReport.getInstance({ appId, appVersion })
      overseaReport.trackPageLoad()
      overseaReport.trackPageLeave()

      resolver.resolve(overseaReport)
    }
  },
  reject: () => {
    resolver.reject()
  },
})
