import { cmsFormItemValueAdaptors } from './adaptors'

/**
 * cms 数据适配器
 */
class CmsDataAdaptor {
  static adapterToTemplateData(cmsValueType: string, data: any) {
    if (!cmsValueType) {
      return data
    }
    const adaptor = cmsFormItemValueAdaptors.find((item) => item.valueType === cmsValueType)
    if (!adaptor) {
      return data
    }
    return adaptor.toTemplateValue(data)
  }
}

export default CmsDataAdaptor
