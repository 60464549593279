import storage from '../storage'

/**
 * 获取认证令牌
 * @returns {string | null} 如果存在认证令牌则返回其值，否则返回 null
 */
export function getAuthToken(): string | null {
  return storage.getItem<string>('auth-token')
}

/**
 * 设置认证令牌
 * @param token - 要存储的认证令牌
 * @param expired - 过期时间 (单位: 秒),可选
 */
export function setAuthToken(token: string, expired?: number): void {
  storage.setItem('auth-token', token, expired)
}

/**
 * 移除认证令牌
 */
export function removeAuthToken(): void {
  storage.removeItem('auth-token')
}

/**
 * 获取或生成状态标识
 * @returns {string} 状态标识
 */
export function getOrGenerateState(): string {
  const state = storage.getItem<string>('state')
  if (state) {
    return state
  }
  const newState = Date.now().toString()
  storage.setItem('state', newState)
  return newState
}
