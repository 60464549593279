import { gaStatistic } from './ga'
import { baiduStatistic } from './baidu'
import { errorReportSM } from './error-report'
import { injectScripts } from './_utils/inject-scripts'
import { drawWatermark } from './draw-watermark'
import { stReport } from './st-report'
import { overseaReport } from './oversea-report'
import { armsReport } from './arms-report'
import { grayout } from './grayout'

/**
 * 注入脚本
 * @description 注入脚本
 */
export const scriptsInjecting = async () => {
  // 声明注入脚本的配置
  const configs = {
    gaStatistic, // 谷歌统计
    baiduStatistic, // 百度统计
    errorReportSM, // 错误上报
    drawWatermark, // 绘制水印
    stReport, // st-report
    overseaReport, // oversea-report
    armsReport, // ARMS 前端监控
    grayout, // 页面置灰模式
  }
  // 调用脚本注入函数，并返回所有脚本注入的 Promise
  await injectScripts(configs)
}
