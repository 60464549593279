/**
 * 异常事件类型
 */
export interface IExceptionEvent {
  /** 错误事件 */
  e?: any[]
  /** Promise 异常 */
  p?: PromiseRejectionEvent
}

/**
 * 初始化异常捕获
 */
export const initExceptionCapture = () => {
  const pendingExceptions: IExceptionEvent[] = []

  const handleError = (...args: any[]) => {
    pendingExceptions.push({ e: [...args] })
  }

  const handleUnhandledRejection = (event: PromiseRejectionEvent) => {
    pendingExceptions.push({ p: event })
  }

  window.addEventListener('error', handleError)
  window.addEventListener('unhandledrejection', handleUnhandledRejection)

  return {
    consumeExceptions: (callback: (exception: IExceptionEvent) => void) => {
      window.removeEventListener('error', handleError)
      window.removeEventListener('unhandledrejection', handleUnhandledRejection)
      pendingExceptions.forEach(callback)
      pendingExceptions.length = 0
    },
  }
}
