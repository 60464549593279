import { v4 } from 'uuid'

/**
 * 唯一的 UV_ID 存储结构
 */
export interface ISoleUvIdStruct {
  /**
   * cookie 值
   */
  value: string
}

/**
 * 唯一的 UV_ID 存储名
 */
const SOLE_UV_ID_STORAGE_NAME = 'general:uv-id'

/**
 * 获取存储
 * @param name
 */
const getStorage = (name: string) => {
  try {
    return JSON.parse(localStorage.getItem(name) || '""')
  } catch (e) {
    console.error(e)
    return null
  }
}

/**
 * 设置存储
 * @param name
 * @param value
 */
const setStorage = (name: string, value: string) => {
  localStorage.setItem(name, value)
}

/**
 * 获取唯一的 uv id
 */
const getSoleUVId = (function () {
  /**
   * 闭包初始化定义 uv id
   */
  let soleUVIdStruct: ISoleUvIdStruct | null = null
  return () => {
    /**
     * 判断是否存在 uv id
     */
    if (!soleUVIdStruct) {
      /**
       * 获取 uv id 结构
       */
      soleUVIdStruct = getStorage(SOLE_UV_ID_STORAGE_NAME) as ISoleUvIdStruct | null
      /**
       * 判断是否存在 uv id 结构
       */
      if (!soleUVIdStruct) {
        /**
         * 不存在时直接创建并返回
         */
        soleUVIdStruct = {
          value: v4(),
        }
        /**
         * 写入到存储
         */
        setStorage(SOLE_UV_ID_STORAGE_NAME, JSON.stringify(soleUVIdStruct))
      }
    }
    return soleUVIdStruct.value
  }
})()

export default getSoleUVId
