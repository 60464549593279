import axios, { AxiosResponse } from 'axios'
import { IRequest } from '../interface'
import { BaseRequest } from '../base-request'

/**
 * AxiosRequest 类用于发送 HTTP 请求
 * @class AxiosRequest
 * @implements IRequest
 */
export class AxiosRequest extends BaseRequest implements IRequest {
  /**
   * 发送 GET 请求
   * @async
   * @method get
   * @param {string} url - 请求的 URL
   * @param {any} [params] - 请求的参数
   * @param {any} [headers] - 请求的头部信息
   * @returns {Promise} - Promise 对象，包含请求的响应数据
   */
  async get<T>(url: string, params?: any, headers?: any): Promise<T> {
    return this.request<T>('get', url, params, undefined, headers)
  }

  /**
   * 发送 POST 请求
   * @async
   * @method post
   * @param {string} url - 请求的 URL
   * @param {any} [data] - 请求的数据
   * @param {any} [headers] - 请求的头部信息
   * @returns {Promise} - Promise 对象，包含请求的响应数据
   */
  async post<T>(url: string, data?: any, headers?: any): Promise<T> {
    return this.request<T>('post', url, undefined, data, headers)
  }

  /**
   * 发送请求
   * @async
   * @method request
   * @param {string} method - 请求的方法，'get' 或 'post'
   * @param {string} url - 请求的 URL
   * @param {any} params - 请求的参数
   * @param {any} [data] - 请求的数据
   * @param {any} [headers] - 请求的头部信息
   * @returns {Promise} - Promise 对象，包含请求的响应数据
   */
  private async request<T>(method: 'get' | 'post', url: string, params?: any, data?: any, headers?: any): Promise<T> {
    // 构造请求的配置
    const config = {
      method,
      params,
      url,
      data,
      headers,
    }
    this.callBeforeRequestListeners(config)
    const response: AxiosResponse<T> = await axios(config)
      .then((response) => {
        this.callAfterResponseSuccessListeners({
          data: response.data,
          headers: response.headers,
          status: response.status,
          statusText: response.statusText,
        })
        return response
      })
      .catch((error) => {
        this.callAfterResponseErrorListeners(error)
        return Promise.reject(error)
      })
    return response.data
  }
}
