import ScriptModel from '../_utils/script-model'
import { isMasterEnv } from '../../constants'
import { isWatermarkEnabled } from '../../biz-url-params'
import { inDisableList } from '../../utils/in-disable-list'

/**
 * 是否启用绘制水印
 */
const isEnabled = !isMasterEnv && isWatermarkEnabled() && !inDisableList('draw-watermark')

/**
 * 绘制水印
 * @description 创建一个ScriptModel实例来处理水印绘制
 * @remarks
 * - 只在非主环境且启用水印时执行
 * - 使用异步和延迟加载来优化性能
 */
export const drawWatermark = new ScriptModel({
  condition: isEnabled,
  attrs: {
    defer: 'true',
    async: 'true',
  },
  script: () => `https://zhcdn01.xoyo.com/xassets/lib/draw-watermark/0.0.x/index.js`,
})
