import ScriptModel from '../_utils/script-model'
import {
  getSentryDsn,
  getSentryEnvironment,
  getSentryRelease,
  getSentryReplaysOnErrorSampleRate,
  getSentryReplaysSessionSampleRate,
} from '../../biz-meta'
import { registerSentryErrorReportPublicVar } from '../../utils/register-public-var'
import errorReport from '../../error-report'
import { inDisableList } from '../../utils/in-disable-list'

/**
 * 获取 Sentry 的 DSN
 */
const dsn = getSentryDsn()

/**
 * 是否启用错误上报
 */
const isEnabled = !!dsn && !inDisableList('error-report')

/**
 * 错误上报
 */
export const errorReportSM = new ScriptModel({
  condition: isEnabled,
  prepare: () => {
    errorReport.initialize(() => {
      return {
        dsn,
        environment: getSentryEnvironment(),
        release: getSentryRelease(),
        replaysSessionSampleRate: getSentryReplaysSessionSampleRate(),
        replaysOnErrorSampleRate: getSentryReplaysOnErrorSampleRate(),
      }
    })
    registerSentryErrorReportPublicVar(errorReport)
  },
})
