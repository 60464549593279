import { getIsGpViaMeta } from '../biz-meta'
import { getOrGenerateState } from '../biz-storage'
import { getAppEnv, getEditorEnv, getIsGpViaUrlParams } from '../biz-url-params'
import { log } from '../logs'

/**
 * 判断当前环境是否是生产环境
 * 生产环境的特征：
 * 1. 不是localhost
 * 3. 不是IP地址
 * 4. 不是以'-'开头的路径
 *
 * 例如:
 * 生产上线地址为: https://pmtest2.seasungames.cn/index-allen/index.html
 * 开发/预览/预发地址为: https://pmtest2.seasungames.cn/-code/site_buider/silxoh1z141z3n7zactach6o/prod/831ba65c5ddf006837f1255d3fe7daf6/index.html
 */
export const isMasterEnv = (function () {
  const appEnv = getAppEnv()

  if (appEnv !== null) {
    return appEnv === 'prod'
  }

  if (location.hostname === 'localhost') {
    return false
  }

  if (location.port && !['80', '443'].includes(location.port)) {
    return false
  }

  const firstHostnamePart = location.hostname.split('.')[0]
  if (['pp-zt', 'qa-zt', 'test-zt'].includes(firstHostnamePart)) {
    return false
  }

  return !/^\d+\.\d+\.\d+\.\d+$/.test(location.hostname) && !location.pathname.split('/')?.[1]?.startsWith('-')
})()

/**
 * API接口
 * @remarks 这个是作用 xxx, 在什么场景使用
 */
export interface IAPI {
  /**
   * 获取页面数据
   * @remarks 这个是作用 xxx, 在什么场景使用
   */
  getPageData: string
  /**
   * 获取图层数据
   */
  getLayerData: string
}

/**
 * 是否是GP
 */
export const isGp = getIsGpViaUrlParams() || getIsGpViaMeta()

/**
 * 当前环境
 */
export const appEnv = getAppEnv() || (isMasterEnv ? 'prod' : 'qa')
const versionAppEnv = appEnv

/**
 * 获取主机名设置
 */
export const getHostnameSettings = () => {
  const editorEnv = getEditorEnv()
  // editorEnv 表示我发布的是大环境是那个, 比如 qa, pp, prod, 此时决定的是请求的域名
  const prefix = editorEnv === 'test' || editorEnv === 'qa' ? 'test-' : editorEnv === 'pp' ? 'pp-' : ''
  const hostnameSettings = {
    gp: {
      client: `${prefix}omni-api.seasungames.com`,
      manager: `${prefix}omni-admin.seasungames.com`,
    },
    cn: {
      client: `${prefix}omni-api.seasungames.cn`,
      manager: `${prefix}omni-admin.seasungames.cn`,
    },
  }
  return hostnameSettings[isGp ? 'gp' : 'cn']
}

/**
 * 获取主机名
 * @returns {string} 返回缓存的主机名或新生成的主机名
 */
const getHostname = () => {
  // 缓存变量，用于存储生成的主机名
  let cache = null

  return (function () {
    if (cache) {
      return cache
    }

    // 这里的生产是生产版本, 生产版本用的 client 端相对路径, 其他情况使用 manager
    cache = getHostnameSettings()[isMasterEnv ? 'client' : 'manager']
    log.debug('cache:hostname', cache)

    return cache
  })()
}

/**
 * 获取当前环境的API操作
 * @returns {IAPI} 返回当前环境的API配置
 */
export const getCurrentApiActions = (function () {
  let cache: IAPI | null = null

  return (): IAPI => {
    if (cache) {
      return cache
    }

    const API_ENV: Record<string, IAPI> = {
      prod: {
        getPageData: `https://${getHostname()}/api/omni/v1/website/page_data/data_by_page_id`,
        getLayerData: `https://${getHostname()}/api/omni/v1/website/page_data/data_by_layer_id`,
      },
      pp: {
        getPageData: `https://${getHostname()}/api/cms_manager/v1/website/page_data/data_by_page_id`,
        getLayerData: `https://${getHostname()}/api/cms_manager/v1/website/page_data/data_by_layer_id`,
      },
      qa: {
        getPageData: `https://${getHostname()}/api/cms_manager/v1/website/page_data/data_by_page_id`,
        getLayerData: `https://${getHostname()}/api/cms_manager/v1/website/page_data/data_by_layer_id`,
      },
    }

    cache = API_ENV[versionAppEnv] || API_ENV.prod
    return cache
  }
})()

/**
 * 获取基础 URL
 * @returns {string} 基础 URL
 */
export const getBaseUrl = (): string => {
  const editorEnv = getEditorEnv()
  return editorEnv === 'test' || !isMasterEnv ? 'test-rock-base-admin.seasungame.com' : 'rock-base-admin.seasungame.com'
}

/**
 * 获取重定向认证 URL
 * @returns {string} 认证重定向 URL
 */
export const getRedirectAuthUrl = (): string => {
  // TODO: 缺省海外地址
  const state = getOrGenerateState()
  const callback = window.location.href
  const env = isMasterEnv ? 'prod' : 'test'
  return `https://${getBaseUrl()}/app/6655847842ca380001e326b3/cpanel/page/67459f9d21c8130001f15151?env=${env}&state=${encodeURIComponent(state)}&callback=${encodeURIComponent(callback)}`
}

/**
 * 获取认证失败重定向 URL
 * @returns {string} 认证失败重定向 URL
 */
export const getRedirectAuthFailureUrl = (errorMessage: string): string => {
  return `https://activity.xoyo.com/p/zt/2024/11/27/omni-login-error/index.html?auth_err_msg=${encodeURIComponent(errorMessage)}`
}

/**
 * 插件数据存储命空间
 */
export const PLUGIN_NAME_SPACE = '_plugin'
