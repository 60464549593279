import { dataReadyAwait } from './awaiter'
import { getAutoFetch, getDebugMode, getPageId } from './biz-meta'
import { getAuthToken, getOrGenerateState, removeAuthToken, setAuthToken } from './biz-storage'
import { getAuthCode, removeAuthCodeFromUrl } from './biz-url-params'
import CmsDataAdaptor from './cms-data-adaptor'
import { getCurrentApiActions, getRedirectAuthUrl, IAPI, isMasterEnv, PLUGIN_NAME_SPACE } from './constants'
import { log } from './logs'
import { merge } from './object-mapper'
import { ILayerDataOption, service } from './service'

/**
 * 配置文件
 */
export interface FunLinkSdkConfig {
  /**
   * 页面 id
   */
  pageId: string | null | undefined
  /**
   * 入口 api
   */
  appEnv: string | null | undefined
  /**
   * 禁用自动请求数据
   */
  autoFetch: boolean
}

/**
 * common 通用 layer
 * pagination 分页数据 layer
 * content 内容 layer
 */
export type LayerType = 'common' | 'pagination' | 'content'

/**
 * layer 类型
 */
const LAYER_TYPE = {
  //  通用 layer
  common: 'common',
  //  分页数据 layer
  pagination: 'pagination',
  // 内容 layer
  content: 'content',
} as const

/**
 * 低代码连接器
 */
export class FunLinkSdk {
  apiActions: IAPI

  private validateSdkConfig() {
    const pageId = getPageId()
    if (!pageId) {
      throw new Error('[fun link sdk]: pageId 缺失')
    }
  }

  private wrapResStruct(data: any) {
    return {
      status: 0,
      data: data,
      msg: '',
    }
  }

  constructor() {
    window.FUN_LINK_SDK = this
    this.validateSdkConfig()
    this.apiActions = getCurrentApiActions()
  }

  /**
   * 启动入口
   */
  public bootstrap() {
    const autoFetch = getAutoFetch()
    if (autoFetch) {
      window[this.getGlobalPromiseName()] = new Promise(async (resolve, reject) => {
        // // 获取认证 token
        // const authToken = getAuthToken()
        // const isDebugMode = getDebugMode()
        // // 如果不是主环境且没有认证 token, 则启动认证流程
        // if (!isDebugMode && !isMasterEnv && !authToken) {
        //   const authResult = await this.startAuthFlow()
        //   if (!authResult) {
        //     return
        //   }
        // }
        this.getPageData().then(resolve).catch(reject)
      })
    }
  }

  /**
   * 请求获取页面数据
   */
  public async getPageData(layerDataOptions?: ILayerDataOption[]) {
    try {
      const pageId = getPageId()

      if (!pageId) {
        throw new Error('[fun link sdk]: pageId 缺失')
      }

      const { layers, plugins, setting } = await service.getPageData({ pageId, layers: layerDataOptions })
      const pageData = this.getDestinationPageData(layers)
      dataReadyAwait.resolve({ pageData, setting })
      return this.wrapResStruct(pageData)
    } catch (error: any) {
      dataReadyAwait.reject(error)
      throw error
    }
  }

  /**
   * 获取 getLayersData
   * @param layerDataOptions
   */
  public async getLayersData(layerDataOptions: ILayerDataOption[]) {
    const pageId = getPageId()

    if (!pageId) {
      throw new Error('[fun link sdk]: pageId 缺失')
    }

    return service.getLayersData({ pageId, layerDataOptions }).then((data) => {
      return this.wrapResStruct(this.getDestinationLayersData(data.layers))
    })
  }

  /**
   * 获取 getLayersData
   * @param layerDataOptions
   */
  public async getPluginData(pluginName: string) {
    const pageId = getPageId()

    if (!pageId) {
      throw new Error('[fun link sdk]: pageId 缺失')
    }

    const pageDataPromise = window[this.getGlobalPromiseName()]

    if (!pageDataPromise) {
      throw new Error('[fun link sdk]: 请先调用 bootstrap 方法')
    }

    return pageDataPromise.then((pageData) => {
      return this.wrapResStruct(pageData.data[PLUGIN_NAME_SPACE][pluginName])
    })
  }

  /**
   * 获取映射前的数据
   * @param layer
   * @param layerType
   * @param mapper
   * @private
   */
  private getMapperSourceData(
    layer: any,
    layerType: LayerType,
    mapper?: {
      datasourceId: string
      positionId: string
    },
  ) {
    if (layerType === LAYER_TYPE.pagination || layerType === LAYER_TYPE.content) {
      return layer.data
    }
    const positionData = layer.positions.find((position: any) => position.positionId === mapper?.positionId)
    return (
      (positionData.datasources || []).find((datasource: any) => datasource.datasourceId === mapper?.datasourceId)
        ?.data || {}
    )
  }

  /**
   * 将layer数据进行数据映射转换为目标数据
   * @param layer
   * @param layerType
   * @private
   */
  private getDestinationLayerData(layer: any, layerType: LayerType = LAYER_TYPE.common) {
    let layerDestinationData = {}
    const variableBindData = layer.variableBinds || []
    variableBindData.forEach((variableBindItem: any) => {
      if (
        variableBindItem.key &&
        variableBindItem.mapper &&
        variableBindItem.mapper.positionId &&
        variableBindItem.mapper.datasourceId &&
        variableBindItem.mapper.key
      ) {
        const mapperSourceData = this.getMapperSourceData(layer, layerType, variableBindItem.mapper)
        const mapperObject = {
          // [`${Array.isArray(mapperSourceData) ? '[].' : ''}${variableBindItem.mapper.key}`]: variableBindItem.key
          [`${Array.isArray(mapperSourceData) ? '[].' : ''}${variableBindItem.mapper.key}`]: {
            key: variableBindItem.key,
            transform: (value: any) => {
              return CmsDataAdaptor.adapterToTemplateData(variableBindItem.mapper.valueType, value)
            },
          },
        }
        merge(mapperSourceData, layerDestinationData, mapperObject)
      }
    })
    return layerDestinationData
  }

  /**
   * 锅炉获取顶层layer数据
   * @param layers
   * @private
   */
  private filterTopLayerData(layers: any[] = []) {
    return layers.filter((layer) => layer.topLayer === true || (layer.layerId || '').split('.').length === 1)
  }

  /**
   * 将页面数据进行数据映射转换为目标数据
   * @param layers
   * @private
   */
  private getDestinationPageData(layers: any[] = []) {
    let pageDestinationData = {}
    this.filterTopLayerData(layers || []).forEach((layer) => {
      const layerData = this.getDestinationLayerData(layer)
      pageDestinationData = {
        ...pageDestinationData,
        ...(layerData || {}),
      }
    })
    log.debug('pageDestinationData', pageDestinationData)
    return pageDestinationData
  }

  /**
   * 将多个layer进行数据映射转换为目标数据
   * @param layers
   * @private
   */
  private getDestinationLayersData(layers: any[] = []) {
    let layersDestinationData = {}
    layers.forEach((layer) => {
      const layerData = this.getDestinationLayerData(layer)
      layersDestinationData = {
        ...layersDestinationData,
        ...(layerData || {}),
      }
    })
    log.debug('layersDestinationData', layersDestinationData)
    return layersDestinationData
  }

  /**
   * 获取 html 中 meta attribute
   * @param attributeName
   * @private
   */
  private getAttributeFormHtml(attributeName: string) {
    return (document.querySelector(`meta[name=${attributeName}]`) as HTMLMetaElement)?.content
  }

  /**
   * 获取 cms 请求 promise 名称
   * @private
   */
  private getGlobalPromiseName() {
    const attributeName = 'data-fun-link-global-promise-name'
    return this.getAttributeFormHtml(attributeName) || '__FUN_LINK_PAGE_DATA_PROMISE__'
  }

  /**
   * 启动认证流程
   * @description 处理认证流程,包括:
   * 1. 如果存在认证码,则使用认证码获取token
   * 2. 如果没有认证码,检查是否存在token
   * 3. 如果没有token,重定向到认证页面
   * @returns {Promise<boolean>} 如果成功获取token返回true,否则返回false
   * @private
   */
  private async startAuthFlow(): Promise<boolean> {
    // 获取认证码
    const authCode = getAuthCode()
    log.debug('startAuthFlow authCode', authCode)
    // 移除 url 中的 auth_code 参数, 避免重复请求或拷贝走当前链接
    removeAuthCodeFromUrl()

    if (authCode) {
      // 使用认证码获取token
      return await service
        .getAuthToken({ authCode, state: getOrGenerateState() })
        .then((res) => {
          log.debug('getAuthToken success', res)
          // 设置token和过期时间
          setAuthToken(res.authToken, res.authVt * 1000)
          return true
        })
        .catch((res) => {
          log.debug('getAuthToken error', res)
          // 如果 status 为 70011（错误），70012（过期），删除 token
          removeAuthToken()
          console.error(res)
          return false
        })
    }
    // 检查是否存在token
    const authToken = getAuthToken()
    log.debug('check authToken', authToken)
    if (!authToken) {
      // 如果没有token,重定向到认证页面
      const redirectUrl = getRedirectAuthUrl()
      log.debug('redirect to auth page', redirectUrl)
      window.location.href = redirectUrl
    }
    return false
  }
}
