import ScriptModel from '../_utils/script-model'
import { getBaiduStatisticId } from '../../biz-meta'
import { isGp } from '../../constants'
import { inDisableList } from '../../utils/in-disable-list'

/**
 * 百度 Id
 */
const baiduId = getBaiduStatisticId()

/**
 * 是否启用百度统计
 */
const isEnabled = !!baiduId && !isGp && !inDisableList('baidu')

/**
 * 百度统计
 */
export const baiduStatistic = new ScriptModel({
  condition: isEnabled,
  script: `//hm.baidu.com/hm.js?${baiduId}`,
  prepare: () => ((window as any)['_hmt'] = window['_hmt'] || []),
})
