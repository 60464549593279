import ScriptModel from '../_utils/script-model'
import { getStReportConfig } from '../../biz-meta'
import { isGp, isMasterEnv } from '../../constants'
import { withResolvers } from '../../utils/with-resolvers'
import { registerStReportPromisePublicVar } from '../../utils/register-public-var'
import { inDisableList } from '../../utils/in-disable-list'

/**
 * 获取国内埋点上报配置
 */
const stReportConfig = getStReportConfig()

/**
 * 是否启用国内埋点上报
 */
const isEnabled = !isGp && !!stReportConfig && !inDisableList('st-report')

/**
 * 国内埋点上报 Resolver
 */
const resolver = withResolvers()

/**
 * 如果未启用国内埋点上报，则直接 reject
 */
if (!isEnabled) {
  resolver.reject()
}

/**
 * 注册国内埋点上报 Promise
 */
registerStReportPromisePublicVar(resolver.promise)

/**
 * st-report 统计
 * @description 用于国内埋点上报
 */
export const stReport = new ScriptModel({
  condition: isEnabled,
  script: `https://zhcdn01.xoyo.com/xassets/lib/st-report-sdk/0.2.x/st-report-sdk.min.js`,
  resolve: () => {
    const stReport = (window as any).StReportSdk.getInstance(stReportConfig)
    stReport.trackPageLoad()
    stReport.trackPageLeave()

    resolver.resolve(stReport)
  },
  reject: () => {
    resolver.reject()
  },
})
