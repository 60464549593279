import ScriptModel from '../_utils/script-model'
import { getGoogleAnalyticsId } from '../../biz-meta'
import { isGp } from '../../constants'
import { inDisableList } from '../../utils/in-disable-list'

/**
 * 谷歌统计 id
 */
const gaId = getGoogleAnalyticsId()

/**
 * 是否启用谷歌统计
 */
const isEnabled = !!gaId && isGp && !inDisableList('ga')

/**
 * 谷歌统计
 */
export const gaStatistic = new ScriptModel({
  condition: isEnabled,
  script: () => `https://www.googletagmanager.com/gtag/js?id=${gaId}`,
  prepare: () => {
    ;(window as any).dataLayer = window.dataLayer || []
    const gtag = function (...args: any[]) {
      ;(window as any).dataLayer.push(arguments)
    }
    gtag('js', new Date())
    gtag('config', gaId)
  },
})
