import { noop } from '../../utils/common-utils'

/**
 * 脚本模型选项接口
 */
export interface IScriptModelOption {
  /**
   * 条件，默认为 true
   */
  condition?: boolean
  /**
   * 脚本地址
   */
  script?: string | (() => string)
  /**
   * 成功回调
   */
  resolve?: () => void
  /**
   * 失败回调
   */
  reject?: (reason?: any) => void
  /**
   * 准备回调，默认为 noop
   */
  prepare?: () => void
  /**
   * 脚本属性
   */
  attrs?: { [key: string]: string }
}

/**
 * 脚本模型类
 */
export default class ScriptModel {
  /**
   * 条件，默认为 true
   */
  condition: boolean
  /**
   * 脚本地址
   */
  script?: string | (() => string) | undefined
  /**
   * 准备回调，默认为 noop
   */
  prepare: () => void
  /**
   * 成功回调
   */
  resolve?: () => void
  /**
   * 失败回调
   */
  reject?: (reason?: any) => void
  /**
   * 脚本属性
   */
  attrs?: { [key: string]: string }

  /**
   * 构造函数
   * @param options 脚本模型选项
   */
  constructor({ condition = true, script, resolve, reject, attrs, prepare = noop }: IScriptModelOption) {
    this.condition = condition
    this.script = script
    this.prepare = prepare
    this.resolve = resolve
    this.reject = reject
    this.attrs = attrs
  }
}
