import ScriptModel from '../_utils/script-model'
import getSoleUVId from '../../utils/get-sole-uv-id'
import { getArmsConfig } from '../../biz-meta'
import { initExceptionCapture } from './cache-exceptions'
import { log } from '../../logs'
import { inDisableList } from '../../utils/in-disable-list'

/**
 * 异常捕获
 */
const { consumeExceptions } = initExceptionCapture()

/**
 * ARMS 配置
 */
const config = getArmsConfig()

/**
 * 获取采样率
 * @returns 采样率
 */
const getSampleRate = async (): Promise<number> => {
  return config?.sampleRate || 1
}

/**
 * 是否启用 ARMS 监控
 */
const isEnabled = !!config && !inDisableList('arms-report')

/**
 * ARMS 前端监控实例
 * 仅在生产环境且配置存在时初始化
 * @see https://help.aliyun.com/zh/arms/user-experience-monitoring/access-web-h5-applications?spm=a2c4g.11186623.help-menu-34364.d_2_0_0_0.1cca596bUFrZXc
 */
export const armsReport = new ScriptModel({
  condition: isEnabled,
  script: config?.src,
  attrs: {
    crossorigin: 'anonymous',
  },
  resolve: async () => {
    log.debug('[ARMS] 初始化 ARMS 监控')
    const ArmsRum = (window as any).RumSDK.default
    const sampleRate = await getSampleRate()
    const isSampled = Math.random() <= sampleRate

    const finalConfig = {
      pid: config?.pid,
      endpoint: config?.endpoint,
      version: config?.version,
      user: {
        tags: getSoleUVId(),
      },
      reportConfig: {
        flushTime: 1,
        maxEventCount: 50,
      },
      beforeReport(bundle: any) {
        // 这里是为了根据 sampled 决定是否上报 exception
        if (isSampled) {
          return bundle
        }
        bundle.events = bundle.events.filter((event: any) => event.event_type !== 'exception')
        if (bundle.events.length === 0) {
          return undefined
        }
        return bundle
      },
    }

    log.debug('[ARMS] 配置:', finalConfig)
    ArmsRum.init(finalConfig)
    log.debug('[ARMS] 初始化 ARMS 监控完成')

    consumeExceptions((exception) => {
      if (exception.e) {
        const [error] = exception.e
        log.debug('[ARMS] 捕获到异常:', error)
        ArmsRum.sendException({
          name: error.name || 'Error',
          message: error.message || String(error),
          file: error.fileName,
          stack: error.stack,
          line: error.lineNumber,
          column: error.columnNumber,
        })
      } else if (exception.p) {
        const error = exception.p.reason
        log.debug('[ARMS] 捕获到 Promise 异常:', error)
        ArmsRum.sendException({
          name: error.name || 'UnhandledRejection',
          message: error.message || String(error),
          file: error.fileName,
          stack: error.stack,
          line: error.lineNumber,
          column: error.columnNumber,
        })
      } else {
        throw new Error('Unknown exception type')
      }
    })
  },
})
