import axios, { AxiosInstance } from 'axios'
import { getCurrentApiActions, getHostnameSettings, isMasterEnv } from '../constants'
import { removeAuthToken } from '../biz-storage'
import { getAuthToken } from '../biz-storage'

/**
 * 获取认证令牌的请求参数接口
 */
export interface IGetAuthTokenParams {
  /** 认证码 */
  authCode: string
  /** 状态标识 */
  state: string
}

/**
 * POST请求参数接口
 */
export interface IPostParams<T> {
  /** 请求URL */
  url: string
  /** 请求数据 */
  data: T
}

export interface IGetParams<T> {
  /** 请求URL */
  url: string
  /** 请求参数 */
  data: T
}

/**
 * 认证令牌响应接口
 */
export interface IAuthTokenResponse {
  /** 认证令牌 */
  authToken: string
  /** 认证有效时间 (单位: 秒) */
  authVt: number
}

export interface IPaginationLayerDataOption {
  perPage: number
  page: number
}

export interface IContentLayerDataOption {
  contentId: string
}

export type ILayerDataOption =
  | {
      layerId: string
      type: 'pagination'
      param: IPaginationLayerDataOption
    }
  | {
      layerId: string
      type: 'content'
      param: IContentLayerDataOption
    }

/**
 * 页面数据请求参数接口
 */
export interface IGetPageDataParams {
  /** 页面ID */
  pageId: string
  /** 层级数据选项 */
  layers?: ILayerDataOption[]
}

/**
 * 页面数据响应接口
 */
export interface IPageDataResponse {
  /** 层级数据 */
  layers: any[]
  /** 插件配置 */
  plugins?: any[]
  /** 页面设置 */
  setting?: any
}

/**
 * 服务类,处理HTTP请求
 */
class Service {
  /** axios实例 */
  private axiosInstance: AxiosInstance = axios.create({})

  constructor() {
    this.initAxiosInstance()
  }

  initAxiosInstance() {
    /**
     * 创建一个axios实例用于HTTP请求
     */
    this.axiosInstance = axios.create({})

    // 拦截请求,添加通用请求头
    this.axiosInstance.interceptors.request.use(
      (config) => {
        if (isMasterEnv) {
          return config
        }
        const authToken = getAuthToken()

        if (authToken) {
          config.headers = {
            ...config.headers,
            'auth-token': authToken,
          }
        }

        return config
      },
      (error) => {
        return Promise.reject(error)
      },
    )

    // 拦截响应
    this.axiosInstance.interceptors.response.use(
      (response: any) => {
        if (isMasterEnv) {
          return response
        }
        const { status } = response.data || {}
        // 处理认证错误和过期
        if (status === 70011 || status === 70012) {
          removeAuthToken()
          console.log(response.data)
          // window.location.href = getRedirectAuthFailureUrl(response.data.msg)
          return Promise.reject(response)
        }
        return response
      },
      (error: any) => {
        return Promise.reject(error)
      },
    )
  }

  /**
   * 发送POST请求
   * @param url - 请求URL
   * @param data - 请求数据
   * @returns 请求响应数据
   * @throws 当响应状态不为0时抛出错误
   */
  private async post<T, R>({ url, data }: IPostParams<T>): Promise<R> {
    const res = await this.axiosInstance.post<{
      status: number
      msg: string
      data: R
    }>(url, data)
    if (res.data.status !== 0) {
      throw res.data
    }
    return res.data.data
  }

  /**
   * 发送GET请求
   * @param url - 请求URL
   * @param data - 请求参数
   * @returns 请求响应数据
   * @throws 当响应状态不为0时抛出错误
   */
  private async get<T, R>({ url, data }: IGetParams<T>): Promise<R> {
    const res = await this.axiosInstance.get<{
      status: number
      msg: string
      data: R
    }>(url, { params: data })
    if (res.data.status !== 0) {
      throw res.data
    }
    return res.data.data
  }

  /**
   * 获取认证令牌
   * @param authCode - 认证码
   * @param state - 状态标识
   * @returns {Promise<IAuthTokenResponse>} 包含认证令牌和有效期的响应对象
   * @example
   * ```ts
   * {
   *   "authToken": "x_gnLN1Xlf+oqg6gGLao4vkvumkcXn+ERUJiDnVw5eZ0/3APvZ0TevanYkgdvT8/Al",
   *   "authVt": 10800
   * }
   * ```
   */
  getAuthToken({ authCode, state }: IGetAuthTokenParams): Promise<IAuthTokenResponse> {
    const url = `https://${getHostnameSettings().manager}/api/cms_manager/v1/website/page_data/get_token`
    return this.post<IGetAuthTokenParams, IAuthTokenResponse>({
      url,
      data: {
        authCode,
        state,
      },
    })
  }

  /**
   * 获取页面数据
   * @param pageId - 页面ID
   * @param layers - 层级数据选项
   * @returns {Promise<IPageDataResponse>} 包含页面数据的响应对象
   */
  getPageData({ pageId, layers }: IGetPageDataParams): Promise<IPageDataResponse> {
    const apiActions = getCurrentApiActions()
    const url = apiActions.getPageData
    return this.get<any, IPageDataResponse>({
      url,
      data: {
        param: {
          pageId,
          layers,
        },
      },
    })
  }

  /**
   * 获取层级数据
   * @param pageId - 页面ID
   * @param layerDataOptions - 层级数据选项
   * @returns {Promise<IPageDataResponse>} 包含层级数据的响应对象
   */
  getLayersData({
    pageId,
    layerDataOptions,
  }: {
    pageId: string
    layerDataOptions: ILayerDataOption[]
  }): Promise<IPageDataResponse> {
    const apiActions = getCurrentApiActions()
    const url = apiActions.getLayerData
    return this.get<any, IPageDataResponse>({
      url,
      data: {
        param: {
          pageId,
          layers: layerDataOptions,
        },
      },
    })
  }
}

/**
 * 导出服务实例
 */
export const service = new Service()
