import { pluginReadyAwait } from '../awaiter'
import { appEnv, isGp } from '../constants'
import { log } from '../logs'
import { loadScript } from '../utils/load-script'
import { withResolvers } from '../utils/with-resolvers'

export enum PluginStatus {
  Idle = 0,
  Pending,
  Resolved,
  Rejected,
}

export interface PluginMeta {
  name: string
  version: string
  defaultVersion: string
  preload: boolean
}

export interface PluginData extends PluginMeta {
  url: string
  status: PluginStatus
  promise?: Promise<void>
}

export interface PluginInstance<T extends object = Record<string, any>> {
  name: string
  version: string
  render(props?: T): void
  unmount(): void
}

export class PluginManager {
  static readonly Config = {
    defaultVersion: '0.x',
    baseUrl: {
      qa: {
        gp: 'https://fe-static.seasungames.com/libs/seasun-official-site/frontend/common-components/components',
        cn: 'https://fe-static.seasungames.cn/libs/seasun-official-site/frontend/common-components/components',
      },
      prod: {
        gp: 'https://fe-static.seasungames.com/libs/seasun-official-site/frontend/common-components/components',
        cn: 'https://fe-static.seasungames.cn/libs/seasun-official-site/frontend/common-components/components',
      },
    },
  }

  readonly ready = pluginReadyAwait.promise
  private plugins: PluginData[] = []

  constructor() {
    this.init()
  }

  private async init() {
    try {
      await this.initMetadata()
      const preloadPlugins = this.plugins.filter((it) => it.preload)
      const results = await Promise.allSettled(preloadPlugins.map((it) => this.loadPlugin(it)))
      const res = results.reduce((res, r, i) => ({ ...res, [preloadPlugins[i].name]: r.status === 'fulfilled' }), {})
      log.info('[PluginManager]', 'Preloaded plugins:', res)
      pluginReadyAwait.resolve(res)
    } catch (error: any) {
      pluginReadyAwait.reject(error)
    }
  }

  private async initMetadata() {
    await this.waitForDomReady()

    const plugins: PluginData[] = []
    const elements = Array.from(document.querySelectorAll<HTMLElement>('script[data-funlink-plugin-name]'))
    for (const { dataset } of elements) {
      const name = dataset.funlinkPluginName?.trim()
      if (!name || !this.validate(name)) {
        log.warn('[PluginManager]', 'Invalid pluginName:', name)
        continue
      }
      if (plugins.findIndex((it) => it.name === name) > -1) {
        log.warn('[PluginManager]', 'Duplicated pluginName:', name)
        continue
      }

      const defaultVersion = this.ensureVersion(dataset.funlinkPluginDefaultVersion?.trim())
      const version = this.ensureVersion(dataset.funlinkPluginVersion?.trim(), defaultVersion)
      const preload = dataset.funlinkPluginPreload !== 'false'
      const url = this.buildUrl(name, version)
      plugins.push({ name, version, defaultVersion, preload, url, status: PluginStatus.Idle })
    }

    this.plugins = plugins
  }

  private waitForDomReady() {
    const { promise, resolve } = withResolvers()
    const ready = () => {
      if (document.readyState === 'complete' || document.readyState === 'interactive') {
        resolve()
        return true
      }
    }

    if (!ready()) {
      document.addEventListener('readystatechange', () => {
        ready() && document.removeEventListener('readystatechange', ready)
      })
    }

    return promise
  }

  private validate(pluginName: string) {
    return !!pluginName && /^@.*\/omni-plugin-.+$/.test(pluginName)
  }

  private unscope(pluginName: string) {
    return pluginName.replace(/^@.*\//, '')
  }

  private ensureVersion(version: string | undefined | null, defaultVersion = PluginManager.Config.defaultVersion) {
    return !!version && /^\d+\.(\d+(\.\d+|\.x)?|x)$/.test(version) ? version : defaultVersion
  }

  private buildUrl(name: string, version: string) {
    const config: Record<string, any> = PluginManager.Config.baseUrl
    const baseUrl = (config[appEnv] || config.prod)[isGp ? 'gp' : 'cn']
    const unscopedName = this.unscope(name)
    return `${baseUrl}/${unscopedName}/${version}/index.global.js`
  }

  private parseName(pluginName: string) {
    const [unscopedName, version] = this.unscope(pluginName).split('@')
    return {
      name: version ? pluginName.replace(`@${version}`, '') : pluginName,
      unscopedName,
      version: this.ensureVersion(version),
    }
  }

  private loadPlugin(plugin: PluginData) {
    if (plugin.status === PluginStatus.Idle || plugin.status === PluginStatus.Rejected) {
      plugin.status = PluginStatus.Pending
      plugin.promise = loadScript(
        plugin.url,
        {
          'funlink-plugin': `${plugin.name}@${plugin.version}`,
        },
        false,
      )
        .then(() => {
          plugin.status = PluginStatus.Resolved
        })
        .catch((e: any) => {
          plugin.status = PluginStatus.Rejected
          log.error('[PluginManager]', 'Failed to load plugin:', plugin.name, e)
          throw e
        })
    } else if (plugin.status === PluginStatus.Resolved) {
      log.warn('[PluginManager]', 'Plugin already loaded:', plugin.name)
    }

    return plugin.promise
  }

  /**
   * 获取插件实例
   *
   * @param pluginName `@scope/omni-plugin-xxx`
   */
  getInstance(pluginName: string) {
    if (!this.validate(pluginName)) return
    const { name, unscopedName } = this.parseName(pluginName)
    if (!this.plugins.find((it) => it.name === name && it.status === PluginStatus.Resolved)) return
    const instance = window[unscopedName] as unknown as PluginInstance
    if (
      instance?.name === unscopedName &&
      typeof instance.render === 'function' &&
      typeof instance.unmount === 'function'
    ) {
      return instance
    }
  }

  /**
   * 加载插件
   *
   * @param pluginName `@scope/omni-plugin-xxx`
   *  - `@scope/omni-plugin-xxx@1.2.3`: 指定具体版本号
   *  - `@scope/omni-plugin-xxx@1.2.x`: 指定主次版本号
   *  - `@scope/omni-plugin-xxx@1.x`: 指定主版本号
   *
   * @notice 插件版本读取优先级: 配置版本 > 指定版本 > 默认版本
   */
  async load(pluginName: string) {
    if (!this.validate(pluginName)) throw new Error('Invalid pluginName')

    await pluginReadyAwait.promise

    const { name, version } = this.parseName(pluginName)
    let plugin = this.plugins.find((it) => it.name === name)
    if (!plugin) {
      plugin = {
        name,
        version,
        defaultVersion: '',
        preload: false,
        url: this.buildUrl(name, version),
        status: PluginStatus.Idle,
      }
      this.plugins.push(plugin)
    }
    await this.loadPlugin(plugin)

    return this.getInstance(plugin.name)
  }
}
