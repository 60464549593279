/**
 * Storage 类的初始化参数接口
 */
export interface IStorageParams {
  /** 存储键值的前缀,默认为 'funlink' */
  prefix?: string
}

/**
 * 存储项的接口定义
 */
export interface IStorageItem<T> {
  /** 存储的数据 */
  value: T
  /** 过期时间戳 */
  expiredAt?: number
}

/**
 * 封装 localStorage 的存储类
 * @remarks
 * 提供了对 localStorage 的封装,支持前缀、过期时间和类型安全的存取操作
 */
class Storage {
  /** 存储键值的前缀 */
  private prefix: string

  /**
   * 创建一个新的 Storage 实例
   * @param params - 初始化参数
   */
  constructor(params?: IStorageParams) {
    this.prefix = params?.prefix || 'funlink'
  }

  /**
   * 从 localStorage 获取数据
   * @param key - 存储键值
   * @returns 存储的数据,如果不存在、已过期或解析失败则返回 null
   * @typeParam T - 存储数据的类型
   */
  getItem<T>(key: string): T | null {
    const value = localStorage.getItem(`${this.prefix}:${key}`)
    if (!value) return null
    try {
      const item = JSON.parse(value) as IStorageItem<T>
      if (item.expiredAt && item.expiredAt < Date.now()) {
        this.removeItem(key)
        return null
      }
      return item.value
    } catch (e) {
      return null
    }
  }

  /**
   * 向 localStorage 存储数据
   * @param key - 存储键值
   * @param value - 要存储的数据
   * @param expired - 过期时间(毫秒),可选
   * @typeParam T - 存储数据的类型
   */
  setItem<T>(key: string, value: T, expired?: number): void {
    const item: IStorageItem<T> = {
      value,
      expiredAt: expired ? Date.now() + expired : undefined,
    }
    localStorage.setItem(`${this.prefix}:${key}`, JSON.stringify(item))
  }

  /**
   * 从 localStorage 移除数据
   * @param key - 存储键值
   */
  removeItem(key: string): void {
    localStorage.removeItem(`${this.prefix}:${key}`)
  }
}

const storage = new Storage()
export default storage
