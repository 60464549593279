export function withResolvers<T = void>(): {
  readonly promise: Promise<T>
  readonly resolve: (value: T | PromiseLike<T>) => void
  readonly reject: (reason?: any) => void
}

/** `Promise.withResolvers` polyfill */
export function withResolvers() {
  // @ts-ignore
  let mix: ReturnType<typeof Promise.withResolvers>
  // @ts-ignore
  if (Promise.withResolvers) {
    // @ts-ignore
    mix = Promise.withResolvers()
  } else {
    let resolve: any
    let reject: any
    const promise = new Promise((res, rej) => {
      resolve = res
      reject = rej
    })
    mix = { promise, resolve, reject }
  }
  // fix: promise reject will log error immediately
  Promise.allSettled([mix.promise])
  return mix
}
