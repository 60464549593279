import ScriptModel from '../_utils/script-model'
import { dataReadyAwait } from '../../awaiter'
import { inDisableList } from '../../utils/in-disable-list'

/**
 * 启用页面置灰模式
 * 通过添加 CSS filter 实现页面置灰效果
 */
const enableGrayMode = () => {
  const style = document.createElement('style')
  style.id = 'fun-link-gray-mode'
  style.innerHTML = `html {
    filter: grayscale(100%) !important;
    transition: filter 0.3s !important;
  }`
  document.head.appendChild(style)
}

/**
 * 是否启用页面置灰模式
 */
const isEnabled = !inDisableList('grayout')

/**
 * 页面置灰模式脚本模型
 * 在页面数据准备好后,根据配置决定是否启用置灰模式
 */
export const grayout = new ScriptModel({
  condition: isEnabled,
  prepare: () => {
    dataReadyAwait.promise.then(({ setting }) => {
      const pageTheme = setting?.['page.theme']
      pageTheme === 'gray' && enableGrayMode()
    })
  },
})
