/**
 * 查询 HTML 文档中的 meta 标签的内容
 * @param name - meta 标签的 name 属性值
 * @returns 如果找到了对应的 meta 标签，则返回它的 content 属性值，否则返回 null
 */
export function queryMeta(name: string): string | null {
  // 查找指定 name 的 meta 标签
  const meta = document.querySelector(`meta[name="${name}"]`)
  if (meta) {
    // 如果找到了 meta 标签，则返回它的 content 属性值
    return meta.getAttribute('content')
  }
  // 如果没有找到 meta 标签，则返回 null
  return null
}
