import { AxiosRequest } from './axios'
import { IRequest } from './interface'

/**
 * 请求类对象
 * @name request
 * @type {IRequest}
 * @description 请求类对象，用于发送 HTTP 请求
 * @example
 * import request from './request';
 * request.get('https://api.example.com/resource');
 */
const request: IRequest = new AxiosRequest()

/**
 * 导出请求类对象
 * @name default
 * @type {IRequest}
 * @description 导出请求类对象，用于发送 HTTP 请求
 * @example
 * import request from './request';
 * request.get('https://api.example.com/resource');
 */
export default request
