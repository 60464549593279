import { withResolvers } from '../utils/with-resolvers'

export interface DataReadyRes {
  /** 页面数据 */
  pageData?: any
  /** 页面配置 */
  setting?: {
    /**
     * 页面主题
     * - 'gray': 置灰
     */
    'page.theme': string
    /**
     * 采样率
     */
    'monitor.arms.sampleRate': number
  }
}

export const dataReadyAwait = withResolvers<DataReadyRes>()

export interface PluginReadyRes {
  [pluginName: string]: boolean
}

export const pluginReadyAwait = withResolvers<PluginReadyRes>()
